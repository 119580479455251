import { parseWithZod } from '@conform-to/zod'
import { invariantResponse } from '@epic-web/invariant'
import {
	json,
	type LoaderFunctionArgs,
	type ActionFunctionArgs,
} from '@remix-run/node'
import { Outlet, useFetchers, useLoaderData } from '@remix-run/react'
import { z } from 'zod'
import { MoonhubLogo } from '#app/components/logo'
import { EpicProgress } from '#app/components/progress-bar'
import { useToast } from '#app/components/toaster'
import { EpicToaster } from '#app/components/ui/sonner'
import { useTheme } from '#app/root'
import { getUserId, logout } from '#app/utils/auth.server'
import { getHints } from '#app/utils/client-hints'
import { getEnv } from '#app/utils/env.server'
import { honeypot } from '#app/utils/honeypot.server'
import { combineHeaders, getDomainUrl } from '#app/utils/misc'
import { getApiClient } from '#app/utils/service-api.server'
import { setTheme, getTheme } from '#app/utils/theme.server'
import { makeTimings, time } from '#app/utils/timing.server'
import { getToast } from '#app/utils/toast.server'
import { getUserSelf } from '#app/.server/api-client/services.gen'

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const client = await getApiClient(request)
	const user = userId
		? await time(() => getUserSelf({ client }), {
				timings,
				type: 'find user',
				desc: 'find user in root',
			})
		: null

	if (userId && !user) {
		console.info(
			'something weird happened: _layout',
			JSON.stringify({ userId, user }),
		)
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user: user?.data,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}
	return json({ result: submission.reply() }, responseInit)
}

export default function AppLayout() {
	const data = useLoaderData<typeof loader>()
	const theme = useTheme()
	useToast(data.toast)

	return (
		<div>
			<div className="flex h-screen flex-col justify-between">
				<header className="container py-6">
					<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
						<MoonhubLogo className="h-7" />
					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</div>
	)
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find((f) => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}
